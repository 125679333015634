import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';

function tinyMce() {
  tinymce.init({
    selector: 'textarea.tinymce',
    plugins: [ 'table', 'lists', 'nonbreaking', 'image' ],
    themes: "silver",
    menubar: 'insert',
    block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
    branding: false,
    toolbar: ['undo redo | styleselect | bold italic | fontselect | fontsizeselect', 'bold italic underline strikethrough | align lineheight | forecolor backcolor | removeformat nonbreaking | image'],
    font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 24pt 36pt 48pt',
    nonbreaking_force_tab: true,
    height: 1000,
    width: 1000
  });
}

export { tinyMce };
