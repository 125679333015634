// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'
import "@fortawesome/fontawesome-free/js/all"
import 'pages'
import 'dashboard'
import 'bootstrap-notify'
import 'demo'
import 'bootstrap-switch'
import 'tooltip'
import 'dropzone'
import 'jquery-mask-plugin'
import 'select2'
import 'select2/dist/css/select2.css'
import 'chartkick/chart.js'
import 'tinymce.min'

import { tinyMce }  from "vendor/tinyMce";

Rails.start()
ActiveStorage.start()
$.jMaskGlobals.watchDataMask = true;

window.addEventListener('DOMContentLoaded', () => {
  //$('.js-states').select2({ theme: 'bootstrap' });
  tinyMce();
})