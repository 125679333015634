$(document).ready(function() {
  $(function () {

    $('[data-toggle="popover"]').popover();
    
    var AUTH_TOKEN=$('meta[name="csrf-token"]').attr('content');

    var personDrozoneTemplate = document.querySelector('#uploaTemplate');
    if ( personDrozoneTemplate ) {
      $("div#my-created-dropzone").dropzone({
        url: $("#person_form").attr('action')+"/file_uploader",
        uploadMultiple: true,
        addRemoveLinks: true,
        parallelUploads: 10,
        dictRemoveFile: '<i class="fas fa-trash"></i> Remover',
        dictCancelUpload: '<i class="fas fa-window-close"></i> Cancelar',
        dictCancelUploadConfirmation: '<i class="fas fa-window-close"></i> Deseja realmente cancelar?',
        previewTemplate: personDrozoneTemplate.innerHTML,
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        successmultiple: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivos enviados com sucesso!</b>');
          $.get($("#person_form").attr('action')+"/reload_file_list.js");
        }
      });
    }

    var personChargeDrozoneTemplate = document.querySelector('#tpl');
    if ( personChargeDrozoneTemplate ) {
      $("div#charge-dropzone").dropzone({
        url: $("#person_charge_form").attr('action')+"/file_uploader",
        uploadMultiple: true,
        addRemoveLinks: true,
        parallelUploads: 10,
        dictRemoveFile: '<i class="fas fa-trash"></i> Remover',
        dictCancelUpload: '<i class="fas fa-window-close"></i> Cancelar',
        dictCancelUploadConfirmation: '<i class="fas fa-window-close"></i> Deseja realmente cancelar?',
        previewTemplate: personChargeDrozoneTemplate.innerHTML,
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        successmultiple: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivos enviados com sucesso!</b>');
          $.get($("#person_charge_form").attr('action')+"/reload_file_list.js");
        }
      });
    }

    var judicialProcessoDrozoneTemplate = document.querySelector('#judicial_process_uploader');
    if ( judicialProcessoDrozoneTemplate ) {
      $("div#charge-dropzone").dropzone({
        url: $("#judicial_process_form").attr('action')+"/file_uploader",
        uploadMultiple: true,
        addRemoveLinks: true,
        parallelUploads: 10,
        dictRemoveFile: '<i class="fas fa-trash"></i> Remover',
        dictCancelUpload: '<i class="fas fa-window-close"></i> Cancelar',
        dictCancelUploadConfirmation: '<i class="fas fa-window-close"></i> Deseja realmente cancelar?',
        previewTemplate: judicialProcessoDrozoneTemplate.innerHTML,
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        successmultiple: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivos enviados com sucesso!</b>');
          $.get($("#judicial_process_form").attr('action')+"/reload_file_list.js");
        }
      });
    }

    var generalFileDrozoneTemplate = document.querySelector('#general_file_uploader');
    if ( generalFileDrozoneTemplate ) {
      $("div#charge-dropzone").dropzone({
        url: $("#general_file_form").attr('action')+"/file_uploader",
        uploadMultiple: true,
        addRemoveLinks: true,
        parallelUploads: 10,
        dictRemoveFile: '<i class="fas fa-trash"></i> Remover',
        dictCancelUpload: '<i class="fas fa-window-close"></i> Cancelar',
        dictCancelUploadConfirmation: '<i class="fas fa-window-close"></i> Deseja realmente cancelar?',
        previewTemplate: generalFileDrozoneTemplate.innerHTML,
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        successmultiple: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivos enviados com sucesso!</b>');
          $.get($("#general_file_form").attr('action')+"/reload_file_list.js");
        }
      });
    }

    var documentModelDrozoneTemplate = document.querySelector('#document_model_uploader');
    if ( documentModelDrozoneTemplate ) {
      $("div#charge-dropzone").dropzone({
        url: $("#document_model_form").attr('action')+"/file_uploader",
        uploadMultiple: true,
        addRemoveLinks: true,
        parallelUploads: 1,
        dictRemoveFile: '<i class="fas fa-trash"></i> Remover',
        dictCancelUpload: '<i class="fas fa-window-close"></i> Cancelar',
        dictCancelUploadConfirmation: '<i class="fas fa-window-close"></i> Deseja realmente cancelar?',
        previewTemplate: documentModelDrozoneTemplate.innerHTML,
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        successmultiple: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivos enviados com sucesso!</b>');
          $.get($("#document_model_form").attr('action')+"/reload_file_list.js");
        },
        success: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivo enviado com sucesso!</b>');
        }
      });
    }

    var realEstateTemplate = document.querySelector('#real_estate_uploader');
    if ( realEstateTemplate ) {
      $("div#charge-dropzone").dropzone({
        url: $("#real_estate_form").attr('action')+"/file_uploader",
        uploadMultiple: true,
        addRemoveLinks: true,
        parallelUploads: 1,
        dictRemoveFile: '<i class="fas fa-trash"></i> Remover',
        dictCancelUpload: '<i class="fas fa-window-close"></i> Cancelar',
        dictCancelUploadConfirmation: '<i class="fas fa-window-close"></i> Deseja realmente cancelar?',
        previewTemplate: realEstateTemplate.innerHTML,
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        successmultiple: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivos enviado com sucesso!</b>');
          $.get($("#real_estate_form").attr('action')+"/reload_file_list.js");
        },
        success: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivo enviado com sucesso!</b>');
        }
      });
    }

    var publicationTemplate = document.querySelector('#publication_uploader');
    if ( publicationTemplate ) {
      $("div#charge-dropzone").dropzone({
        url: $("#publication_id").attr('value')+"/file_uploader",
        uploadMultiple: false,
        addRemoveLinks: false,
        parallelUploads: 1,
        dictRemoveFile: '<i class="fas fa-trash"></i> Remover',
        dictCancelUpload: '<i class="fas fa-window-close"></i> Cancelar',
        dictCancelUploadConfirmation: '<i class="fas fa-window-close"></i> Deseja realmente cancelar?',
        previewTemplate: publicationTemplate.innerHTML,
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        successmultiple: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivos enviado com sucesso!</b>');
        },
        success: function(data,response){
          demo.showNotification('top', 'center', 1, '<b style="margin:auto; display:table">Arquivo enviado com sucesso!</b>');
        }
      });
    }

    $('#carouselExampleSlidesOnly').carousel({
      interval: 2000,
      pause: 'hover'
    })
  })
});